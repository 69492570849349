@font-face {
    font-family: 'Sofia';
    src: local('Sofia'), url(./fonts/sofiaprosoftreg-webfont.woff) format('woff');
  }


body {
    @extend .bg-light;
    font-family: 'Sofia';
}

h1, h2, h3, h4, h5, h6 {
    color: $primary !important
}

.partial-background:before {
    content: '';
    display: block;
    height: 250px;
    margin-bottom: -250px;
    background: radial-gradient(circle at 15% 50%, #7f289d 0%, #5a489c 20%, #3f6a8d 50%, #56b97b 75%, #9bdd5d 100% );
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: 100% 302%;
    background-position: left;
}

$sidebar-width: 250px;

@include media-breakpoint-up(lg) {
    .partial-background {
        margin-left: $sidebar-width;
    }
}

.sidebar {
    width: $sidebar-width;
    position: fixed;
}

.caret-off::before, .caret-off::after {
    display: none;
}

.content-section {
    @extend .bg-white;
    @extend .rounded;
    @extend .shadow-sm;
    @extend .p-2;
    @extend .p-lg-4;
    @extend .mb-4;
}

