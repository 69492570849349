.history-graph {
    position: relative;
    margin: 20px 0;

    div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        visibility: hidden;
        background-color: #EEEEEE00;

        .spinner-border {
            margin: auto;
            width: 50px;
            height: 50px;
            border-width: 3px;
        }
    }

    &.loading {
        div {
            visibility: visible;
            background-color: #EEEEEE90;
            transition: background-color 0.5s, visibility;
            transition-delay: 1s;
        }
    }

}
