$success: #2E3788;
$primary: #2E3788;
$body-color: #6c757d;
$min-contrast-ratio: 3;

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.min";

@import "layout";
@import "sortable-columns";
@import "overview-table";
@import "react-select";
@import "editable-div";
@import "utils";
@import "graph";
@import "system-detail";
@import "historical-errors";
