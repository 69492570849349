div.editable_div_bootstrap {
    border: 1px solid $gray-400 !important;
    border-radius: 0.375rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    padding: 12px;
    display: block; 
    outline: 0px;

    &:focus, :active {
        border-color: $primary !important;
        box-shadow: 0 0 0 0.25rem rgba($primary, .25) !important;
    }
}