.systems-overview {
    display: grid;

    .system-row {
        display: contents;

        .system {
            position: relative;

            .device-visualization {
                position: relative;

                .device-image {
                    position: relative;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 100%;
                    aspect-ratio: 1/1;
                    max-height: 150px;
                    z-index: 4;
                }

                .device-image-connecting-line {
                    position: absolute;
                    top: calc(50% - 1px);
                    left: 50%;
                    right: -50%;
                    z-index: 3;
                    border-bottom: 2px dashed $primary;
                }
            }

            &:last-child .device-image-connecting-line {
                display: none;
            }

            .device-information {
                cursor: pointer;
                width: 100%;
                text-align: center;
                word-break: break-all;

                span.active {
                    font-weight: bold;
                }
            }
        }
    }
}

.device-wrapper {
    text-decoration: none;
    color: $body-color;
}

.tab-content > .tab-pane {
    display: block !important;
    overflow: hidden;
    height: 0;

    &.active {
        height: 100%;
        overflow: initial;
    }
}