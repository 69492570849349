.react-select-bootstrap-control {
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    border-radius: var(--bs-border-radius) !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

    &-focused {
        border-color: tint-color($primary, 50) !important;
        box-shadow: 0 0 0 0.25rem rgba($primary, .25) !important;
    }

    &-disabled {
        background-color: $gray-200 !important;
    }

    &-arrow {
        color: $gray-800 !important;
    }
}

.react-select-dropdown-menu-list {
    display: table;
    width: 100%;

    div {
        display: table-row;

        span {
            display: table-cell;
            padding: 7px 10px;
            white-space: nowrap;

            &:last-child {
                width: 100%;
            }
        }
    }
}

.react-select-value-container {
    span {
        padding-right: 10px;
    }
}

.react-select-option-highlight {
    background-color: tint-color($primary, 75) !important;
}

.input-group .react-select-bootstrap-control:not(:first-child) {
    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
