.error-history {
    display: inline-grid;
    grid-template-columns: auto 5px auto;
    padding-bottom: 20px;

    .error-group-button {
        position: relative;

        .error-load-more {
            position: absolute;
            top: 30px;
            transform: translateX(-50%);
        }
    }
    .error-line-button {
        border-left: 5px solid $secondary-bg-subtle;
        height: 30px;
        margin-bottom: 50px;
    }

    .error-group {
        display: contents;

        .error-times {
            text-align: right;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &-start {
                transform: translateY(50%);
            }

            &-end {
                transform: translateY(-50%);
                margin-bottom: auto;
            }
        }

        .error-line {
            min-height: 100px;
        }

        .error-table {
            padding: 15px;
            .table { margin-bottom: 0; }
        }

        &.error-level- {
            &service_required {
                .error-line { border-left: 5px solid $info; }
                .error-table { background-color: $info-bg-subtle; }
                // .error-table table { @extend .table-info; }
            }

            &error {
                .error-line { border-left: 5px solid $danger; }
                .error-table { background-color: $danger-bg-subtle; }
                // .error-table table { @extend .table-danger; }
            }

            &warning {
                .error-line { border-left: 5px solid $warning; }
                .error-table { background-color: $warning-bg-subtle; }
                // .error-table table { @extend .table-warning; }
            }

            &none {
                .error-line { border-left: 5px dashed $secondary; }
                .error-table, .error-line { background-color: $light; }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .error-history {
        grid-template-columns: 1fr 5px 1fr;
    }
}
